<template>
  <div>
    <BaseModal
      size="md"
      :title="detailInfo.title.label"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <BaseLabel :columnInfo="detailInfo.message" />

        <BaseDetailInputGroup
          v-model="vehicleGroupName"
          :columnInfo="detailInfo.vehicle_group_name"
          :rules="[INPUT_TYPE_CHECK.REQUIRED]"
          maxLength="20"
          :columnType="COLUMN_TYPE.TEXT"
        />

        <BaseDetailInputGroup
          v-model="note"
          :columnInfo="detailInfo.note"
          maxLength="60"
          :columnType="COLUMN_TYPE.TEXT"
        />
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 登録ボタン -->
        <BaseButton
          variant="primary"
          :columnInfo="detailCtrlInfo.register"
          @click="register"
        />
        <!-- キャンセルボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="detailCtrlInfo.cancel"
          @click="cancel"
        />
      </template>
    </BaseModal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vehicleGroupName: null,
      note: null,
    }
  },

  //   props: {
  //     vehicleIdList: {
  //       type: Array,
  //       required: true,
  //     },
  //   },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.MANUAL_PLANNING_VEHICLE_GROUP_REG
      )('detail_ctrl_info')
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.MANUAL_PLANNING_VEHICLE_GROUP_REG
      )('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
  },

  methods: {
    register() {
      this.$bvModal.hide('manual-planning-reg-modal')
      this.$emit('after-close-set', this.vehicleGroupName, this.note)
    },
  },
}
</script>
